<template>
  <div class="m-con">
	<div class="m-main u-container">
		<ul>
			<li v-for="item in list">
				<div class="u-flex-col">
					<p class="m-title">{{item.title}}</p>
					<p class="m-time">{{item.created_at}}</p>
				</div>
				<a @click="downFile(item)" class="m-btn">下载</a>
			</li>
		</ul>
	</div>
  </div>
</template>

<script>
	import {getCourseDetail} from "@/api/index.js"
	export default {
		data(){
			return {
				info:{},
				list:[]
			}
		},
		mounted() {
			this.getInfo()
		},
		methods:{
			downFile(item){
				var x = new XMLHttpRequest();
				    x.open("GET", item.path, true);
				    x.responseType = 'blob';
				    x.onload=function(e) {
				        //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
				        var url = window.URL.createObjectURL(x.response)
				        var a = document.createElement('a');
				        a.href = item.path
				        a.download = item.title;
				        a.click()
				    }
				    x.send();
			},
			async getInfo(){
				let res = await getCourseDetail({course_id:this.$route.query.id})
				if(res.code == 200){
					this.info = res.data.course
					this.list = res.data.course_path || []
				}else{
					this.$message.error(res.message)
					this.$router.back()
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-con{
		width: 100%;
		height: 100%;
		background: #F1F1F1;
		padding-bottom: 100px;
		padding-top: 70px;
		.m-main{
			min-height: 600px;
			background: #fff;
			padding-bottom: 60px;
			border: 1px solid #D7D7D7;
			.m-main-title{
				text-align: center;
				font-size: 24px;
				font-family: Microsoft YaHei;
				color: #2D2D2D;
				margin: 0 20px;
				height: 126px;
				line-height: 126px;
				border-bottom: 1px dashed #E5E5E5;
			}
			.m-main-font1{
				font-size: 16px;
				color: #999999;
				line-height: 16px;
			}
			.m-main-text{
				font-size: 16px;
				color: #383838;
				padding: 50px;
				line-height: 24px;
			}
			
			ul{
				list-style: none;
				margin: 20px;
				li{
					display: flex;
					align-items: center;
					justify-content: space-between;
					list-style: none;
					width: 100%;
					    height: 143px;
					    border-bottom: 1px solid #d9d9d9;
					.m-title{
						font-size: 18px;
						color: #333;
						font-weight: 600;
					}
					.m-time{
						font-size: 14px;
						color: #999;
						margin-top: 10px;
					}
					.m-btn{
						width: 132px;
						height: 45px;
						text-align: center;
						line-height: 45px;
						border-radius: 4px;
						background: #42ACDC;
						font-size: 14px;
						color: #fff;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
